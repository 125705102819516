import { fetchGet, fetchPost } from './helper';

const AMP_API = process.env.REACT_APP_AMP_API;
const TENANCY_NAME = process.env.REACT_APP_AMP_TENANCY_NAME;

const getAbsoluteUrlFromStrapiImage = (image) => {
    return process.env.REACT_APP_API_DOMAIN + image.url;
}

export default class {
    static trackPage(page) {
        page = page || "home";
        fetchGet(`${AMP_API}/api/api/RulesTrigger/Track/${TENANCY_NAME}/${page}`);
    }

    static sendContactUs(data) {
        const { firstName, lastName, emailAddress, cellPhone, city, groupName, bookingType, dateDesired, numberOfRooms, requestType, questions = [], captchaResponse } = data;

        const body = {
            firstName,
            lastName,
            emailAddress,
            cellPhone, 
            city, 
            groupName, 
            bookingType, 
            dateDesired, 
            numberOfRooms,
            requestType,
            questions,
            captchaResponse
        }
        return fetchPost(`${AMP_API}/api/api/services/app/Contact/ContactUs/${TENANCY_NAME}`, body);
    }

    static async getSavedFavorites(email) {
        try {
            const { result } = await fetchGet(`${AMP_API}/api/api/RulesTrigger/GetProspectFavorites`, { email, tenancyName: TENANCY_NAME })
            let { favouriteRooms = [], favouriteLMS = [], favouritePackages = [], favourite48Hours = [] } = result;

            favouriteRooms = favouriteRooms.reduce((prev, { roomId, status }) => {
                if (status === 0) prev[roomId] = true;
                return prev;
            }, {});

            favouriteLMS = favouriteLMS.reduce((prev, { lmsId, status }) => {
                if (status === 0) prev[lmsId] = true;
                return prev;
            }, {});

            favouritePackages = favouritePackages.reduce((prev, { packageId, status }) => {
                if (status === 0) prev[packageId] = true;
                return prev;
            }, {});

            favourite48Hours = favourite48Hours.reduce((prev, { hoursId, status }) => {
                if (status === 0) prev[hoursId] = true;
                return prev;
            }, {});

            return { favouriteRooms, favouriteLMS, favouritePackages, favourite48Hours };
        } catch {
            console.log("There was an error getting saved favorites");
            return { favouriteRooms: [], favouriteLMS: [], favouritePackages: [], favourite48Hours: [] };
        }
    }


    static async saveFavoriteRoom(user, roomId, roomName, roomDescription, roomPrice, StrapiPhoto, isSave = true) {
        const { firstName, lastName, emailAddress } = user;
        const body = {
            firstName,
            lastName,
            emailAddress,
            status: isSave ? 0 : 1,
            tenancyName: TENANCY_NAME,

            roomId,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto)
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Favourite/Rooms`, body);
    }

    static async saveFavoriteLMS(user, lmsId, name, roomName, roomDescription, roomPrice, StrapiPhoto, startDate, endDate, isSave = true) {
        const { name: firstName, surname: lastName, emailAddress } = user;
        const body = {
            firstName,
            lastName,
            emailAddress,
            status: isSave ? 0 : 1,
            tenancyName: TENANCY_NAME,

            lmsId,
            name,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto),
            startDate,
            endDate
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Favourite/LMS`, body);
    }

    static async saveFavoritePackage(user, packageId, name, roomName, roomDescription, roomPrice, StrapiPhoto, startDate, endDate, isSave = true) {
        const { name: firstName, surname: lastName, emailAddress } = user;
        const body = {
            firstName,
            lastName,
            emailAddress,
            status: isSave ? 0 : 1,
            tenancyName: TENANCY_NAME,

            packageId,
            name,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto),
            startDate,
            endDate
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Favourite/Packages`, body);
    }

    static async saveFavorite48Hours(user, hoursId, hourSubject, hourDetails, StrapiPhoto, isSave = true) {
        const { name: firstName, surname: lastName, emailAddress } = user;
        const body = {
            firstName,
            lastName,
            emailAddress,
            status: isSave ? 0 : 1,
            tenancyName: TENANCY_NAME,

            hoursId,
            hourSubject,
            hourDetails,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto)
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Favourite/48Hours`, body);
    }

    static async shareFavoriteRoom(firstName, lastName, emailAddress, message, captchaResponse, roomId, roomName, roomDescription, roomPrice, StrapiPhoto) {
        const body = {
            firstName,
            lastName,
            emailAddress,
            tenancyName: TENANCY_NAME,
            captchaResponse,
            message,

            roomId,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto),
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Share/Forward-Rooms`, body);
        return response.success;
    }

    static async shareFavoriteLMS(firstName, lastName, emailAddress, message, captchaResponse, lmsId, name, roomName, roomDescription, roomPrice, StrapiPhoto, startDate, endDate,) {
        const body = {
            firstName,
            lastName,
            emailAddress,
            tenancyName: TENANCY_NAME,
            captchaResponse,
            message,

            lmsId,
            name,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto),
            startDate,
            endDate
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Share/Forward-LMS`, body);
        return response.success;
    }

    static async shareFavoritePackage(firstName, lastName, emailAddress, message, captchaResponse, packageId, name, roomName, roomDescription, roomPrice, StrapiPhoto, startDate, endDate,) {
        const body = {
            firstName,
            lastName,
            emailAddress,
            tenancyName: TENANCY_NAME,
            captchaResponse,
            message,

            packageId,
            name,
            roomName,
            roomDescription,
            roomPrice,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto),
            startDate,
            endDate
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Share/Forward-Packages`, body);
        return response.success;
    }

    static async shareFavorite48Hours(firstName, lastName, emailAddress, message, captchaResponse, hoursId, hourSubject, hourDetails, StrapiPhoto) {
        const body = {
            firstName,
            lastName,
            emailAddress,
            tenancyName: TENANCY_NAME,
            captchaResponse,
            message,

            hoursId,
            hourSubject,
            hourDetails,
            photo: getAbsoluteUrlFromStrapiImage(StrapiPhoto)
        }
        const response = await fetchPost(`${AMP_API}/api/api/RulesTrigger/Share/Forward-48Hours`, body);
        return response.success;
    }
}
