import React, { useState, useEffect } from 'react';
import { IoMdCall, IoMdMail, IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, withRouter, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { Img } from 'component/FrameL';

import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import './style.css';

// function DesktopNavbar() {
//   const [isOpen, setIsOpen] = useState(true);
//   const [bookingStart, setBookingStart] = useState(moment(new Date()));
//   const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
//   const [bookingFocusedInput, setBookingFocusedInput] = useState(null);

//   const { pathname } = useLocation();

//   useEffect(() => {
//     const handleScrollClose = () => {
//       if (window.scrollY < 50) {
//         setIsOpen(true);
//       } else {
//         setIsOpen(false);
//       }
//     }

//     document.addEventListener('scroll', handleScrollClose);

//     return () => {
//       document.removeEventListener('scroll', handleScrollClose);
//     }
//   }, []);

//   return (<>
//     <div className={`fixed top-0 left-0 m-4 z-50 flex items-center`}>
//       <button onMouseMove={() => {
//         setIsOpen(true);
//       }}
//         aria-label="Menu"
//         className="navbar-bg-color p-2 tailwind-block">
//         <IoMdMenu className="text-4xl"></IoMdMenu>
//       </button>
//     </div>
//     <div className={`navbar__bg-container ${isOpen ? 'visible' : ''}`}>
//       <ul className="xl:container navbar__content pt-16 pb-2 tracking-20 uppercase mx-auto flex justify-center items-end" >
//         <li>
//           <Link className={pathname === '/' && 'active'} to="/">
//             Home
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/rooms' && 'active'} to="/rooms">
//             Rooms
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/thingstodo' && 'active'} to="/thingstodo">
//             Nantucket
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/dining' && 'active'} to="/dining">
//             Dining
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/about' && 'active'} to="/about">
//             About
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/gallery' && 'active'} to="/gallery">
//             Gallery
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/press' && 'active'} to="/press">
//             Press
//           </Link>
//         </li>
//         <li>
//           <Link className={pathname === '/packages' && 'active'} to="/packages">
//             Offers
//           </Link>
//         </li>
//         <li>
//           <a href="https://secure.thinkreservations.com/unioninn/gift-certificates"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Gift Certificates
//           </a>
//           {/* <Link className={pathname === '/last-minute-specials' && 'active'} to="/last-minute-specials">
//             Last-Minute Specials
//           </Link> */}
//         </li>
//       </ul>
//       <div className={`fixed mt-4 mr-4 top-0 right-0 flex flex-row z-10`}>
//         <DateRangePicker
//           startDate={bookingStart}
//           startDateId="start"
//           endDate={bookingEnd}
//           endDateId="end"
//           onDatesChange={({ startDate, endDate }) => {
//             setBookingStart(startDate);
//             setBookingEnd(endDate);
//           }}
//           focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
//           onFocusChange={focusedInput => setBookingFocusedInput(focusedInput)}
//         >
//         </DateRangePicker>
//         <a
//           href={`https://secure.thinkreservations.com/unioninn/reservations/availability?start_date=${bookingStart && bookingStart.format('YYYY-MM-DD')}&end_date=${bookingEnd && bookingEnd.format('YYYY-MM-DD')}`}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="navbar-bg-color mr-2 btn-sm border-2 border-primary-blue uppercase">
//           Book Now
//         </a>
//       </div>
//     </div>
//   </>
//   );
// }

function MobileNavbar({ CoreData }) {
  const [isOpen, setIsOpen] = useState(false);
  const { logoDark, logoLight, contactNumber, email, announcement } = CoreData;

  const { pathname } = useLocation();

  return (<div className="relative z-20">
    <div className='absolute w-full'>
      <Link to='/' className='tailwind-block navbar__logo bg-black bg-opacity-80 mx-auto'>
        <Img Image={logoLight} className=''></Img>
      </Link>

      {announcement && <ReactMarkdown className='bg-black bg-opacity-80 text-white mt-32 text-center text-lg p-2'>{announcement}</ReactMarkdown>}
    </div>

    <div className={`fixed top-0 left-0 right-0 flex flex-row items-start justify-between  pointer-events-auto`}>
      <button className="m-4 p-2 bg-black bg-opacity-80" aria-label="Menu" onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-white icon"></IoMdMenu>
      </button>
      <div className='relative m-4 cursor-pointer' id='order-menu'>
        <div className='btn btn-primary text-accent-2 border-b border-black w-44'>Order Online</div>
        {/* <a className='btn btn-primary text-accent-2 m-4' target="_blank" href="https://togoorder.com/web/5099#/">
        Order Online
      </a> */}
        <div className='absolute top-0 right-0 transition transition-height ease-in-out delay-150 overflow-hidden' id='order-menu-options'>
          <div className='btn btn-primary text-accent-2 border-b border-black'>
            <a className='no-underline' target="_blank" href="https://togoorder.com/web/5099#/">Catering</a>
          </div>
          <div className='btn btn-primary text-accent-2 border-b border-black'>
            <a className='no-underline' target="_blank" href="https://order.online/store/blue-ribbon-bbq-arlington-25982093/?hideModal=true&pickup=true">Arlington</a>
          </div>
          <div className='btn btn-primary text-accent-2 border-b border-black'>
            <a className='no-underline' target="_blank" href="https://order.online/store/blue-ribbon-bbq-dedham-25982152/?hideModal=true&pickup=true">Dedham</a>
          </div>
          <div className='btn btn-primary text-accent-2 border-b border-black'>
            <a className='no-underline' target="_blank" href="https://order.online/store/blue-ribbon-bbq-newton-25982218/?hideModal=true&pickup=true">West Newton</a>
          </div>
          <div className='btn btn-primary text-accent-2 border-b border-black'>
            <a className='no-underline' target="_blank" href="https://gotab.io/loc/timeoutmarketboston/menu/blueribbonbbq~blue-ribbon-bbq">Time Out Market Boston</a>
          </div>
        </div>
      </div>
    </div>

    <div className={`bg-primary-red text-white p-4 py-8 mobile-nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 icon m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <ul className="navbar__content stack-l text-accent" style={{ "--space": "var(--s2)" }}>
        <li>
          <Link className={pathname === '/' && 'active'} to="/" onClick={() => {
            setIsOpen(false);
          }}>
            <Img Image={logoDark}></Img>
          </Link>
        </li>
        <li>
          <Link className={pathname === '/arlington' && 'active'} to="/arlington" onClick={() => {
            setIsOpen(false);
          }}>
            Arlington
          </Link>
        </li>
        <li>
          <Link className={pathname === '/boston' && 'active'} to="/boston" onClick={() => {
            setIsOpen(false);
          }}>
            Boston
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dedham' && 'active'} to="/dedham" onClick={() => {
            setIsOpen(false);
          }}>
            Dedham
          </Link>
        </li>
        <li>
          <Link className={pathname === '/west-newton' && 'active'} to="/west-newton" onClick={() => {
            setIsOpen(false);
          }}>
            West Newton
          </Link>
        </li>
        <li>
          <Link className={pathname === '/catering' && 'active'} to="/catering" onClick={() => {
            setIsOpen(false);
          }}>
            Catering & Events
          </Link>
        </li>
        <li>
          {/* <Link className={pathname === '/online-store' && 'active'} to="/online-store" onClick={() => {
            setIsOpen(false);
          }}> */}
          <a href="https://brbbq.ecwid.com/" target="_blank">
            Online Store
          </a>
          {/* </Link> */}
        </li>
        <li>
          <Link className={pathname === '/news' && 'active'} to="/news" onClick={() => {
            setIsOpen(false);
          }}>
            In the News
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' && 'active'} to="/about" onClick={() => {
            setIsOpen(false);
          }}>
            About
          </Link>
        </li>
        <li>
          <Link className={pathname === '/allergies' && 'active'} to="/allergies" onClick={() => {
            setIsOpen(false);
          }}>
            Allergies
          </Link>
        </li>
        <li>
          <Link className={pathname === '/charity' && 'active'} to="/charity" onClick={() => {
            setIsOpen(false);
          }}>
            Charity
          </Link>
        </li>
        <li>
          <Link className={pathname === '/contact' && 'active'} to="/contact" onClick={() => {
            setIsOpen(false);
          }}>
            Contact
          </Link>
        </li>

        <li>
          <a href={`tel:${contactNumber}`}>
            <IoMdCall className='inline' /> {contactNumber}
          </a>
        </li>

        <li>
          <a href={`mailto:${email}`}>
            <IoMdMail className='inline' /> Email Us
          </a>
        </li>
      </ul>
    </div>
  </div>
  );
}

const NavBar = ({ cmsData }) => {

  return (
    <React.Fragment>
      <MobileNavbar CoreData={cmsData} />
    </React.Fragment>
  );
}

export default withRouter(NavBar);