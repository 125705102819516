import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { AiFillFacebook } from 'react-icons/ai';
import { FaTwitter, FaInstagram, FaYoutubeSquare, FaTwitterSquare } from 'react-icons/fa';

import { Img } from "component/FrameL";

import PhantomGourment from "assets/phantom-gourmet.png";
import BestOfBoston from "assets/best-of-boston.png";
import CitySearch from "assets/citysearch.png";
import BespokeLogo from "assets/bespoke_footer_icon.png";
import './style.css';

const Footer = ({ cmsData }) => {
    const { brandName, contactNumber, facebook, instagram, youtube, twitter } = cmsData;
    return <>
        <footer className="relative bg-cover py-8 px-4 md:px-8 z-0 stack-l text-white bg-black text-center" style={{ '--space': 'var(--s2)' }}>
            <div>
                Get the latest in Blue Ribbon BBQ news, events and specials. Join our mailing list.
            </div>

            <div className='max-w-screen-md mx-auto flex flex-row gap-4 items-center'>
                <div>
                    <Img src={BestOfBoston} isExternal={false}></Img>
                </div>
                <div>
                    <Img src={CitySearch} isExternal={false}></Img>
                </div>
                <div>
                    <Img src={PhantomGourment} isExternal={false}></Img>
                </div>
            </div>

            <div>
                <div className='cluster-l' style={{ "--justify": "center" }}>
                    <a href="https://brbbq.ecwid.com/" className='text-white no-underline text-accent-2' target='_blank'>Online Store</a>
                    <Link to="/about" className='text-white no-underline text-accent-2'>About</Link>
                    <Link to="/catering" className='text-white no-underline text-accent-2'>Catering</Link>
                    <Link to="/contact" className='text-white no-underline text-accent-2'>Contact</Link>
                </div>
            </div>

            <div>
                <div className='stack-l' style={{ '--space': '0' }}>
                    <p>
                        {contactNumber}
                    </p>
                    <p>
                        Arlington, Boston, Dedham & West Newton MA
                    </p>
                    <p>
                        © {new Date().getFullYear()} {brandName}
                    </p>
                </div>
            </div>

            <div className="flex flex-row items-center justify-center text-4xl gap-1">
                <a href={instagram} className='text-white' target="_blank" aria-label="Instagram Link">
                    <FaInstagram></FaInstagram>
                </a>
                <a href={facebook} className='text-white' target="_blank" aria-label="Facebook Link">
                    <AiFillFacebook></AiFillFacebook>
                </a>
                <a href={youtube} className='text-white' target="_blank" aria-label="Instagram Link">
                    <FaYoutubeSquare></FaYoutubeSquare>
                </a>
                <a href={twitter} className='text-white' target="_blank" aria-label="Instagram Link">
                    <FaTwitterSquare></FaTwitterSquare>
                </a>
            </div>

            <div className='relative sm:absolute m-4 bottom-0 right-0'>
                <a href="https://bespoke.house" target='_blank'>
                    <Img className='mx-auto' isExternal={false} src={BespokeLogo} alt="Bespoke Logo" />
                </a>
            </div>
        </footer>
    </>;
};

export default Footer;
